'use strict';
const axios = require('axios');

window.addEventListener('load', () => {
  const choosePersonal = document.querySelector('.choosePersonal');
  const registrationPopup = document.querySelector('.popup-wrap_registration .popup');
  const popupBackground = document.querySelector('.index-wrap');
  const chooseProfessional = document.querySelector('.chooseProfessional');
  const chooseEnterprise = document.querySelector('.chooseEnterprise');
  const chooseEnterpriseMobile = document.querySelector('.chooseEnterpriseMobile');
  const choosePersonalMobile = document.querySelectorAll('.choosePersonalMobile');
  const chooseProfessionalMobile = document.querySelectorAll('.chooseProfessionalMobile');

  const loaderIcon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
  <circle cx="16" cy="3" r="0">
    <animate attributeName="r" values="0;3;0;0" dur="1s" repeatCount="indefinite" begin="0" keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8" calcMode="spline"></animate>
  </circle>
  <circle transform="rotate(45 16 16)" cx="16" cy="3" r="0.311605">
    <animate attributeName="r" values="0;3;0;0" dur="1s" repeatCount="indefinite" begin="0.125s" keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8" calcMode="spline"></animate>
  </circle>
  <circle transform="rotate(90 16 16)" cx="16" cy="3" r="1.3149">
    <animate attributeName="r" values="0;3;0;0" dur="1s" repeatCount="indefinite" begin="0.25s" keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8" calcMode="spline"></animate>
  </circle>
  <circle transform="rotate(135 16 16)" cx="16" cy="3" r="2.92647">
    <animate attributeName="r" values="0;3;0;0" dur="1s" repeatCount="indefinite" begin="0.375s" keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8" calcMode="spline"></animate>
  </circle>
  <circle transform="rotate(180 16 16)" cx="16" cy="3" r="2.43257">
    <animate attributeName="r" values="0;3;0;0" dur="1s" repeatCount="indefinite" begin="0.5s" keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8" calcMode="spline"></animate>
  </circle>
  <circle transform="rotate(225 16 16)" cx="16" cy="3" r="1.17473">
    <animate attributeName="r" values="0;3;0;0" dur="1s" repeatCount="indefinite" begin="0.625s" keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8" calcMode="spline"></animate>
  </circle>
  <circle transform="rotate(270 16 16)" cx="16" cy="3" r="0">
    <animate attributeName="r" values="0;3;0;0" dur="1s" repeatCount="indefinite" begin="0.75s" keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8" calcMode="spline"></animate>
  </circle>
  <circle transform="rotate(315 16 16)" cx="16" cy="3" r="0">
    <animate attributeName="r" values="0;3;0;0" dur="1s" repeatCount="indefinite" begin="0.875s" keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8" calcMode="spline"></animate>
  </circle>
  <circle transform="rotate(180 16 16)" cx="16" cy="3" r="2.43257">
    <animate attributeName="r" values="0;3;0;0" dur="1s" repeatCount="indefinite" begin="0.5s" keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8" calcMode="spline"></animate>
  </circle>
</svg>`;

  if (((choosePersonal && chooseProfessional && chooseEnterprise)
    || (choosePersonalMobile.length && chooseProfessionalMobile.length && chooseEnterpriseMobile.length))
    && registrationPopup && popupBackground) {

    choosePersonal.addEventListener('click', event => {
      if (!choosePersonal.classList.contains('button_plan')) {
        updateSubscription('personal', choosePersonal);
      }
    });

    chooseProfessional.addEventListener('click', event => {
      if (!chooseProfessional.classList.contains('button_plan')) {
        updateSubscription('professional', chooseProfessional);
      }
    });

    chooseEnterprise.addEventListener('click', event => {
      if (!chooseEnterprise.classList.contains('button_plan')) {
        updateSubscription('enterprise', chooseEnterprise);
      }
    });

    chooseEnterpriseMobile.addEventListener('click', event => {
      if (!chooseEnterpriseMobile.classList.contains('button_plan')) {
        updateSubscription('enterprise', chooseEnterpriseMobile);
      }
    });

    choosePersonalMobile.forEach(item => {
      item.addEventListener('click', event => {
        if (!item.classList.contains('button_plan')) {
          updateSubscription('personal', item);
        }
      });
    });

    chooseProfessionalMobile.forEach(item => {
      item.addEventListener('click', event => {
        if (!item.classList.contains('button_plan')) {
          updateSubscription('professional', item);
        }
      });
    });

    function updateSubscription(type, button) {
      if (user && user.confirmed === 0) {
        window.location.replace('/app/subscription?notVerified=true');
      }
      else if (user && !user.stripe_customer_id) {
        window.location.replace('/app/subscription?noPayment=true');
      }
      else if (user) {

        axios({
          method: 'get',
          url: `/api/stripe/get-billing-address`,
          headers: {
            "content-type": "application/json"
          }
        })
          .then(response => {
            if (!response.data.address) {
              window.location.replace('/app/subscription?noBillingInformation=true&subsciption=' + type);
            } else {
              button.disabled = true;
              button.innerHTML = loaderIcon;
              axios({
                method: 'post',
                url: `/api/user/update-subscription`,
                headers: {
                  "content-type": "application/json"
                },
                data: {
                  subscription: type,
                }
              })
                .then(response => {
                  if (response.data.success) {
                    window.location.reload();
                  }
                  button.disabled = false;
                  button.innerHTML = `Choose ${type.charAt(0).toUpperCase() + type.slice(1)}`;
                })
                .catch(error => {
                  button.disabled = false;
                  button.innerHTML = `Choose ${type.charAt(0).toUpperCase() + type.slice(1)}`;
                })
            }
          })
          .catch(error => {
            button.disabled = false;
            button.innerHTML = `Choose ${type.charAt(0).toUpperCase() + type.slice(1)}`;
          });

      } else {
        window.history.replaceState(null, null, '/signup');
        document.body.classList.add('overflowed');
        registrationPopup.classList.add('popup_active');
      }
    }
  }
});
