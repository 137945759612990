'use strict';

import Glide from '@glidejs/glide';

window.addEventListener('load', () => {
    // Slider
    const sliderElements = ['.glide_pricing'].filter(el => document.querySelector(el));

    [].map.call(sliderElements, el => {
        const glide = new Glide(el, {
            type: 'carousel',
            gap: 30,
            swipeThreshold: 50,
            perView: 1,
            peek: {
                before: 120,
                after: 120,
            },
            startAt: 1,
            breakpoints: {
                605: {
                    peek: {
                        before: 80,
                        after: 80,
                    },
                },
                470: {
                    peek: {
                        before: 50,
                        after: 50,
                    },
                },
                365: {
                    peek: {
                        before: 20,
                        after: 20,
                    },
                },
            },
            focusAt: 'center',
        }).mount();
    });
});
