'use strict';
const topics = document.querySelectorAll('.topic');

if (topics.length !== 0) {
    const topicButtons = document.querySelectorAll('.topic__open');

    [].map.call(topicButtons, el => {
        el.onclick = () => {
            if (window.innerWidth > 991) {
                const elTopic = $(el).parents(".topic");
                $(elTopic).toggleClass('active');
                if ($(elTopic).hasClass('active')) {
                    $(".active .topic__button").text('Close Answer')
                } else {
                    $(".topic .topic__button").text('Read Answer')
                }
            }
        }
    });

    [].map.call(topics, el => {
        el.onclick = () => {
            if (window.innerWidth < 991) {
                el.classList.toggle('active')
            }
        };
    })

}