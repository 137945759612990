'use strict';
import axios from 'axios';

window.addEventListener('load', () => {
    const loginButton = document.querySelector('#loginButton');
    const forgotButton = document.querySelector('#forgotButton');
    const forgotConfirm = document.querySelector('#forgotConfirm');
    const newPasswordButton = document.querySelector('#newPasswordButton');
    const signupButton = document.querySelector('#signupButton');

    const popupBackground = document.querySelector('.index-wrap');
    const loginPopup = document.querySelector('.popup-wrap_login .popup');
    const passRecoverSecondPopup = document.querySelector('.popup-wrap_pass-recover-second .popup');
    const passRecoverPopup = document.querySelector('.popup-wrap_pass-recover .popup');
    const sentAgain = document.querySelector('#sentAgain');

    const signupPasswordLabel = document.querySelector('#signupPasswordLabel');
    const signupEmailLabel = document.querySelector('#signupEmailLabel');
    const passwordLabel = document.querySelector('#passwordLabel');
    const loginErrorLabel = document.querySelector('#loginErrorLabel');
    const forgotErrorLabel = document.querySelector('#forgotErrorLabel');
    const newPasswordLabel = document.querySelector('#newPasswordLabel');
    const targetInput = document.querySelector('#target');

    const loaderIcon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
  <circle cx="16" cy="3" r="0">
    <animate attributeName="r" values="0;3;0;0" dur="1s" repeatCount="indefinite" begin="0" keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8" calcMode="spline"></animate>
  </circle>
  <circle transform="rotate(45 16 16)" cx="16" cy="3" r="0.311605">
    <animate attributeName="r" values="0;3;0;0" dur="1s" repeatCount="indefinite" begin="0.125s" keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8" calcMode="spline"></animate>
  </circle>
  <circle transform="rotate(90 16 16)" cx="16" cy="3" r="1.3149">
    <animate attributeName="r" values="0;3;0;0" dur="1s" repeatCount="indefinite" begin="0.25s" keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8" calcMode="spline"></animate>
  </circle>
  <circle transform="rotate(135 16 16)" cx="16" cy="3" r="2.92647">
    <animate attributeName="r" values="0;3;0;0" dur="1s" repeatCount="indefinite" begin="0.375s" keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8" calcMode="spline"></animate>
  </circle>
  <circle transform="rotate(180 16 16)" cx="16" cy="3" r="2.43257">
    <animate attributeName="r" values="0;3;0;0" dur="1s" repeatCount="indefinite" begin="0.5s" keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8" calcMode="spline"></animate>
  </circle>
  <circle transform="rotate(225 16 16)" cx="16" cy="3" r="1.17473">
    <animate attributeName="r" values="0;3;0;0" dur="1s" repeatCount="indefinite" begin="0.625s" keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8" calcMode="spline"></animate>
  </circle>
  <circle transform="rotate(270 16 16)" cx="16" cy="3" r="0">
    <animate attributeName="r" values="0;3;0;0" dur="1s" repeatCount="indefinite" begin="0.75s" keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8" calcMode="spline"></animate>
  </circle>
  <circle transform="rotate(315 16 16)" cx="16" cy="3" r="0">
    <animate attributeName="r" values="0;3;0;0" dur="1s" repeatCount="indefinite" begin="0.875s" keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8" calcMode="spline"></animate>
  </circle>
  <circle transform="rotate(180 16 16)" cx="16" cy="3" r="2.43257">
    <animate attributeName="r" values="0;3;0;0" dur="1s" repeatCount="indefinite" begin="0.5s" keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8" calcMode="spline"></animate>
  </circle>
</svg>`;


    loginButton.addEventListener('click', event => {
        event.preventDefault();
        const loginEmail = document.querySelector('#loginEmail').value;
        const loginPassword = document.querySelector('#loginPassword').value;
        loginErrorLabel.innerHTML = '';
        passwordLabel.innerHTML = '';
        loginButton.innerHTML = loaderIcon;
        loginButton.disabled = true;
        axios({
            method: 'post',
            url: `/auth/login`,
            errorHandle: false,
            headers: {
                "content-type": "application/json"
            },
            data: {
                email: loginEmail,
                password: loginPassword
            }
        })
            .then(response => {
                loginButton.innerHTML = "Login";
                loginButton.disabled = false;
                if (response.data.loginError) {
                    loginErrorLabel.innerHTML = response.data.loginError;

                }
                if (response.data.passwordError) {
                    passwordLabel.innerHTML = response.data.passwordError;
                }
                else if (response.data.success) {
                    document.body.classList.remove('overflowed');
                    popupBackground.classList.remove('modal-blur');
                    loginPopup.classList.remove('popup_active');
                    loginErrorLabel.classList.remove('error__label');
                    passwordLabel.classList.remove('error__label');
                    if (targetInput.value) {
                        window.location.replace(targetInput.value);
                    } else {
                        window.location.replace('/app/account-info');
                    }
                }
            });
    });

    let forgotEmail;

    forgotButton.addEventListener('click', event => {
        event.preventDefault();
        forgotEmail = document.querySelector('#forgotEmail').value;
        forgotErrorLabel.innerHTML = '';
        forgotButton.innerHTML = loaderIcon;
        forgotButton.disabled = true;
        axios({
            method: 'post',
            url: `/auth/forgot-password`,
            headers: {
                "content-type": "application/json"
            },
            errorHandle: false,
            data: {
                email: forgotEmail,
            }
        })
            .then(response => {
                forgotButton.innerHTML = "Send";
                forgotButton.disabled = false;
                if (response.data.forgotError) {
                    forgotErrorLabel.innerHTML = response.data.forgotError;
                    forgotErrorLabel.classList.add('error__label');
                } else if (response.data.email) {
                    passRecoverPopup.classList.remove('popup_active');
                    forgotErrorLabel.classList.remove('error__label');
                    passRecoverSecondPopup.classList.add('popup_active');
                }
            });
    });

    signupButton.addEventListener('click', event => {
        event.preventDefault();
        signupPasswordLabel.innerHTML = '';
        signupEmailLabel.innerHTML = '';
        signupButton.innerHTML = loaderIcon;
        signupButton.disabled = true;
        const email = document.querySelector('#signupEmail').value;
        const password = document.querySelector('#signupPassword').value;
        axios({
            method: 'post',
            url: `/signup`,
            errorHandle: false,
            headers: {
                "content-type": "application/json"
            },
            data: {
                email,
                password
            }
        })
            .then(response => {
                signupButton.innerHTML = "Create Account";
                signupButton.disabled = false;
                if (response.data.passwordError) {
                    signupPasswordLabel.classList.add('error__label');
                    signupPasswordLabel.innerHTML = response.data.passwordError;
                }
                if (response.data.emailError) {
                    signupEmailLabel.classList.add('error__label');
                    signupEmailLabel.innerHTML = response.data.emailError;
                } else if (response.data.success) {
                    axios({
                        method: 'post',
                        url: `/auth/login`,
                        errorHandle: false,
                        headers: {
                            "content-type": "application/json"
                        },
                        data: {
                            email,
                            password
                        }
                    })
                        .then((loginResponse) => {
                            localStorage.setItem('firstLogin', loginResponse.data && (loginResponse.data.lastLogin === null))
                            window.location.replace('/app/account-info');
                        });
                }
            }).catch(e => e);
    });

    forgotConfirm.addEventListener('click', event => {
        event.preventDefault();
        forgotConfirm.disabled = true;
        axios({
            method: 'post',
            url: `/auth/forgot-password`,
            errorHandle: false,
            headers: {
                "content-type": "application/json"
            },
            data: {
                email: forgotEmail,
            }
        })
            .then(response => {
                forgotConfirm.disabled = false;
                sentAgain.classList.add('popup__confirm');
                sentAgain.innerHTML = 'A confirmation email has been sent again.';
            });
    });

    newPasswordButton.addEventListener('click', event => {
        event.preventDefault();
        const newPassword = document.querySelector('#newPassword').value;
        const token = window.location.search.substr(7, 40);
        newPasswordLabel.innerHTML = '';
        newPasswordButton.innerHTML = loaderIcon;
        newPasswordButton.disabled = true;
        axios({
            method: 'post',
            url: `/profile/recover-password/${token}`,
            errorHandle: false,
            headers: {
                "content-type": "application/json"
            },
            data: {
                newPassword,
            }
        })
            .then(response => {
                newPasswordButton.innerHTML = "Login";
                newPasswordButton.disabled = false;
                if (response.data.passwordError) {
                    newPasswordLabel.innerHTML = response.data.passwordError;
                } else if (response.data.success) {
                    window.location.replace('/app/');
                }
            });
    })
});
