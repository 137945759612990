const header = document.querySelector('.header');
const logoutButton = document.querySelector('#logout');


window.onscroll = () => {
    if (window.innerWidth > 767) {
        const scrolled = window.pageYOffset || document.documentElement.scrollTop;

        if (scrolled) {
            header.classList.add('header_shaded')
        } else {
            header.classList.remove('header_shaded')
        }
    }
}

if(logoutButton) {
    logoutButton.onclick = () => {
        document.cookie = 'sharedCount=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        window.Intercom('shutdown');
        window.location.reload();
    };
}


