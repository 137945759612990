const tabs = document.querySelectorAll('.tab-switcher .tab-switcher__tab');

[].map.call(tabs, el => {
    el.onclick = () => {

        const activeTabSwitcher = el.parentElement.querySelector('.tab-switcher .tab-switcher__tab_active');

        if (el !== activeTabSwitcher) {
            // Removing active className from current active tab
            const activeTabName = activeTabSwitcher.dataset.tabtrigger;
            const activeTab = document.querySelector(`[data-tabName="${activeTabName}"]`);
            activeTab.classList.remove('tab-content_visible');

            // Adding active className to new active tab
            document.querySelector(`[data-tabName="${el.dataset.tabtrigger}"]`).classList.add('tab-content_visible');

            // Tab switcher toggling
            activeTabSwitcher.classList.remove('tab-switcher__tab_active');
            el.classList.toggle('tab-switcher__tab_active');
        }
    }
});
