//polyfills
import 'babel-polyfill';

import * as Toastr from "toastr";

const axios = require('axios');

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    Toastr.error(error.message || 'Something went wrong.');
});

document.getElementById("footerDate").innerHTML = new Date().getFullYear();

const url = window.location.pathname;
const urlsplit = url.split().slice()[0];
const tabSwitcher = $(".aside .tab-switcher__tab");

for(let i = 0; i < tabSwitcher.length; i++){
    if (tabSwitcher.eq(i).attr('href') === urlsplit) {
        tabSwitcher.eq(i).addClass('tab-switcher__tab_active');
    }
}

// Lottie animations
import './animations';

// Mobile menu
import './mobile-menu';

// Header shadow on scroll
import './header';

// main popup's
import './main-p-popup';

// page pricing
import './pricing';

// page faq
import './faq';

// Tabs switching
import './tabs-switcher';

import './web';

import './subscription';

