'use strict';
import zxcvbn from 'zxcvbn';
import axios from 'axios';
import "password-strength-meter";

function replaceRouteState(url = '/') {
    window.history.replaceState(null, null, '/');
    window.history.replaceState(null, null, url);
}

window.addEventListener('load', () => {
    const loginAccDropDown = document.querySelector('.login-acc');
    const settingsDropDown = document.querySelector('.dropdown-acc');
    const popupBackground = document.querySelector('.index-wrap');
    const registrationPopupTriger = [...document.querySelectorAll('.btn_registration')];
    const registrationPopupSubTriger = document.querySelector('.register-triger');
    const loginPopupTriger = [...document.querySelectorAll('.btn_login')];
    const fbLoginButton = document.querySelector('#fbloginbutton');
    const loginPopupSubTriger = document.querySelector('.login-triger');
    const passRecoverTriger = document.querySelector('.login-pass-wrap__reliability_forgot');
    const firstPopupTriggers = [...document.querySelectorAll('button.button_pricing-cart')];

    const emailMissingPopup = document.querySelector('.popup-wrap_email-missing-warrning .popup');
    const registrationPopup = document.querySelector('.popup-wrap_registration .popup');
    const loginPopup = document.querySelector('.popup-wrap_login .popup');
    const passRecoverPopup = document.querySelector('.popup-wrap_pass-recover .popup');
    const passRecoverSecondPopup = document.querySelector('.popup-wrap_pass-recover-second .popup');
    const signupSuccessPopup = document.querySelector('.invalid-file-modal .popup');
    const signupErrorPopup = document.querySelector('.invalid-token-modal .popup');
    const usedTokenPopup = document.querySelector('.used-token-modal .popup');
    const meters = document.querySelector(".login-pass-wrap__progress");

    const passNewPopup = document.querySelector('.popup-wrap_new-pass .popup');
    const signupPasswordLabel = document.querySelector('#signupPasswordLabel');
    const signupEmailLabel = document.querySelector('#signupEmailLabel');
    const passwordLabel = document.querySelector('#passwordLabel');
    const loginErrorLabel = document.querySelector('#loginErrorLabel');
    const forgotErrorLabel = document.querySelector('#forgotErrorLabel');
    const newPasswordLabel = document.querySelector('#newPasswordLabel');
    const closePopupTriggers = [...document.querySelectorAll(`.popup-wrap_registration .popup__close-btn,
                                                          .popup-wrap_login .popup__close-btn,
                                                          .popup-wrap_pass-recover .popup__close-btn,
                                                          .popup-wrap_pass-recover-second .popup__close-btn,
                                                          .popup-wrap_new-pass .popup__close-btn,
                                                          .invalid-file-modal .popup__close-btn,
                                                          .invalid-token-modal .popup__close-btn,
                                                          .used-token-modal .popup__close-btn,
                                                          .recover-close-triger`)];

    const passInput = document.getElementById('signupPassword');
    const newPassInput = document.getElementById('newPassword');
    const signUpSectionButton = document.querySelector('#signUpSectionButton');
    const aboutSectionButton = document.querySelector('#aboutSectionButton');

    if (registrationPopupTriger) {
        if (window.location.pathname === '/signup') {
            replaceRouteState('signup');
            document.body.classList.add('overflowed');
            registrationPopup.classList.add('popup_active');
            signupPasswordLabel.innerHTML = '';
            signupEmailLabel.innerHTML = '';
        }

        registrationPopupTriger.forEach((item) => {
            item.onclick = () => {
                replaceRouteState('signup');
                document.body.classList.add('overflowed');
                registrationPopup.classList.add('popup_active');
                signupPasswordLabel.innerHTML = '';
                signupEmailLabel.innerHTML = '';
            };
        });
    }

    if (loginPopupTriger) {
        if (window.location.pathname === '/login') {
            document.body.classList.add('overflowed');
            loginPopup.classList.add('popup_active');
            loginErrorLabel.innerHTML = '';
            passwordLabel.innerHTML = '';
        }
        loginPopupTriger.forEach((item) => {
            item.onclick = () => {
                replaceRouteState('login');
                document.body.classList.add('overflowed');
                loginPopup.classList.add('popup_active');
                loginErrorLabel.innerHTML = '';
                passwordLabel.innerHTML = '';
            };
        });
    }

    if (registrationPopupSubTriger) {
        registrationPopupSubTriger.onclick = () => {
            replaceRouteState('signup');
            document.body.classList.add('overflowed');
            loginPopup.classList.remove('popup_active');
            registrationPopup.classList.add('popup_active');
            signupPasswordLabel.innerHTML = '';
            signupEmailLabel.innerHTML = '';
        };
    }

    if (fbLoginButton) {

        window.fbAsyncInit = function () {
            FB.init({
                appId: '229685553736265',
                xfbml: true,
                cookie: true,
                version: 'v8.0'
            });

        };

        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "//connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));

        fbLoginButton.onclick = () => {
            FB.login(function (response) {
                axios({
                    method: 'post',
                    url: `/auth/fb-login`,
                    errorHandle: false,
                    headers: {
                        "content-type": "application/json"
                    },
                    data: {
                        token: FB.getAccessToken(),
                        expired_date: response.authResponse.data_access_expiration_time
                    }
                })
                    .then((res) => {
                        if (res && !res.data.user_email) {
                            loginPopup.classList.remove('popup_active');
                            emailMissingPopup.classList.add('popup_active');
                        } else {
                            window.location.replace('/app/account-info');
                        }
                    });

            });
        };
    }

    if (loginPopupSubTriger) {
        loginPopupSubTriger.onclick = () => {
            document.body.classList.add('overflowed');
            registrationPopup.classList.remove('popup_active');
            loginPopup.classList.add('popup_active');
            loginErrorLabel.innerHTML = '';
            passwordLabel.innerHTML = '';
        };
    }

    if (passRecoverTriger) {
        if (window.location.pathname === '/reset') {
            document.body.classList.add('overflowed');
            loginPopup.classList.remove('popup_active');
            passRecoverPopup.classList.add('popup_active');
            forgotErrorLabel.innerHTML = '';
        }
        passRecoverTriger.onclick = () => {
            replaceRouteState('reset');
            document.body.classList.add('overflowed');
            loginPopup.classList.remove('popup_active');
            passRecoverPopup.classList.add('popup_active');
            forgotErrorLabel.innerHTML = '';
        };
    }

    if (firstPopupTriggers) {
        [].map.call(firstPopupTriggers, el => {
            el.onclick = () => {
                document.body.classList.add('overflowed');
            };
        });
    }

    if (loginAccDropDown) {

        $(window).click(function (e) {
            if (!$(e.target).hasClass('login-acc')) {
                settingsDropDown.classList.remove('dropdown-acc--active');
            }
        });

        loginAccDropDown.onclick = () => {
            settingsDropDown.classList.add('dropdown-acc--active');
        };
    }

    if (window.location.search.substr(1, 5) === 'token' && window.location.search.substr(7, 40).length === 40) {
        document.body.classList.add('overflowed');
        passRecoverPopup.classList.remove('popup_active');
        passNewPopup.classList.add('popup_active');
    }

    const url_string = window.location.href
    const url = new URL(url_string);
    const isVerified = url.searchParams.get("verified");

    if (isVerified === 'success') {
        signupSuccessPopup.classList.add('popup_active');
    }

    if (isVerified === 'failure') {
        usedTokenPopup.classList.add('popup_active');
    }

    if (isVerified === 'invalid') {
        signupErrorPopup.classList.add('popup_active');
    }

    if (closePopupTriggers) {
        [].map.call(closePopupTriggers, el => {
            el.onclick = () => {
                replaceRouteState(url.pathname);
                signupPasswordLabel.innerHTML = "";
                signupEmailLabel.innerHTML = "";
                passwordLabel.innerHTML = "";
                loginErrorLabel.innerHTML = "";
                forgotErrorLabel.innerHTML = "";
                newPasswordLabel.innerHTML = "";
                [...document.querySelectorAll("input")].forEach(item => item.value = "");
                document.body.classList.remove('overflowed');
                registrationPopup.classList.remove('popup_active');
                loginPopup.classList.remove('popup_active');
                passRecoverPopup.classList.remove('popup_active');
                passRecoverSecondPopup.classList.remove('popup_active');
                passNewPopup.classList.remove('popup_active');
                popupBackground.classList.remove('modal-blur');
                usedTokenPopup.classList.remove('popup_active');
                signupSuccessPopup.classList.remove('popup_active');
                signupErrorPopup.classList.remove('popup_active');
                meters.value = 0
            };
        });
    }

    loginPopupSubTriger.onclick = () => {
        replaceRouteState('login');
        document.body.classList.add('overflowed');
        registrationPopup.classList.remove('popup_active');
        loginPopup.classList.add('popup_active');
        loginErrorLabel.innerHTML = '';
        passwordLabel.innerHTML = '';
    };

    if (signUpSectionButton) {
        signUpSectionButton.onclick = () => {
            replaceRouteState('signup');
            document.body.classList.add('overflowed');
            registrationPopup.classList.add('popup_active');
            signupPasswordLabel.innerHTML = '';
            signupEmailLabel.innerHTML = '';
        };
    }

    if (aboutSectionButton) {
        aboutSectionButton.onclick = () => {
            replaceRouteState('signup');
            document.body.classList.add('overflowed');
            registrationPopup.classList.add('popup_active');
            signupPasswordLabel.innerHTML = '';
            signupEmailLabel.innerHTML = '';
        };
    }

    const handleEnter = (button) => (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            button.click();
        }
    };

    const loginEmail = document.getElementById("loginEmail");
    const loginPassword = document.getElementById("loginPassword");
    const loginButton = document.getElementById("loginButton");
    loginEmail.addEventListener('keydown', handleEnter(loginButton));
    loginPassword.addEventListener('keydown', handleEnter(loginButton));

    const signUpEmail = document.getElementById("signupEmail");
    const signUpPassword = document.getElementById("signupPassword");
    const signUpButton = document.getElementById("signupButton");
    signUpEmail.addEventListener('keydown', handleEnter(signUpButton));
    signUpPassword.addEventListener('keydown', handleEnter(signUpButton));

    const forgotEmail = document.getElementById("forgotEmail");
    const forgotButton = document.getElementById("forgotButton");
    forgotEmail.addEventListener('keydown', handleEnter(forgotButton));

    const newPassword = document.getElementById("newPassword");
    const newPasswordButton = document.getElementById("newPasswordButton");
    newPassword.addEventListener('keydown', handleEnter(newPasswordButton));

    //password strength

    const strength = {
        0: "Worst",
        1: "Bad",
        2: "Weak",
        3: "Good",
        4: "Strong"
    };

    let passStrength = (e) => {
        const thisElem = e.target;
        const meter = $(thisElem).siblings('.login-pass-wrap__progress');
        const text = $(thisElem).siblings('.login-pass-wrap__reliability');
        const val = e.target.value;
        const result = zxcvbn(val);

        meter.val(result.score);

        val !== "" ? text.text(strength[result.score]) :
            text.text("");
    };

    passInput.addEventListener('input', passStrength);
    newPassInput.addEventListener('input', passStrength);
});

