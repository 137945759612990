import lottie from 'lottie-web'


const animationContainers = [
    'about',
    'scalable',
    'reliable',
    'powerful',
    'key',
    'integrate',
    'analyze'
];


animationContainers.forEach(el => {
    const params = {
        container: document.getElementById(`lottie-${el}`),
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: require(`../assets/animations/${el}.json`)
    };

    if (params.container) {
        lottie.loadAnimation(params);
    }
})
