const mobileMenu = document.querySelector('.mobile-menu');
const mobileMenuOpenButton = document.querySelector('.header__burger-menu button');
const mobileMenuCloseButton = document.querySelector('.mobile-menu__close button');

mobileMenuOpenButton.onclick = () => {
  mobileMenu.classList.add('mobile-menu_visible');
  document.body.classList.add('overflowed');
};

mobileMenuCloseButton.onclick = () => {
  mobileMenu.classList.remove('mobile-menu_visible');
  document.body.classList.remove('overflowed');
};
